<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <Estructura />
    </v-col>
  </v-row>
</template>

<script>
import Estructura from './tablas/Estructura.vue'

export default {
  components: {
    Estructura,
  },
}
</script>
