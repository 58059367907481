<template>
  <v-card>
    <v-card-title>{{ $t('menu.translate') }} / {{ $t('menu.estructura') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="translateView('')"
          >
            <v-icon>mdi-translate</v-icon>
            <span>{{ $t('menu.translate') }}</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="text-right"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.pageWeb') | lowercase }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th width="10px"></th>
            <th class="text-uppercase">
              {{ $t('lbl.pageWeb') }}
            </th>
            <th
              width="10%"
              class="text-uppercase"
              style="text-align: right;"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(iten, index) in items">
            <template>
              <!--NIVEL 0-->
              <tr
                :key="index"
              >
                <td>
                  <template v-if="iten.childs.length > 0">
                    <v-icon
                      v-if="iten.open"
                      style="cursor: pointer;"
                      color="primary"
                      @click="changeOpen(index)"
                    >
                      mdi-minus-box-outline
                    </v-icon>
                    <v-icon
                      v-else
                      style="cursor: pointer;"
                      color="primary"
                      @click="changeOpen(index)"
                    >
                      mdi-plus-box-outline
                    </v-icon>
                  </template>
                </td>
                <td>
                  <span
                    v-for="(name, indM) in iten.name"
                    :key="name"
                  >
                    {{ $t(name) }}
                    <span v-if="indM !== iten.name.length - 1"> / </span>
                  </span>
                </td>
                <td
                  style="text-align: right;"
                >
                  <v-tooltip
                    v-if="iten.page"
                    color="primary"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="cursor: pointer;"
                        color="primary"
                        v-bind="attrs"
                        :disabled="!iten.page"
                        @click="goUrl(iten.page)"
                        v-on="on"
                      >
                        mdi-web
                      </v-icon>
                    </template>
                    <span>
                      {{ $t('btn.views') }}
                    </span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="cursor: pointer;"
                        color="primary"
                        v-bind="attrs"
                        class="ml-4"
                        :disabled="!iten.slug"
                        v-on="on"
                        @click="translateView(iten.slug)"
                      >
                        mdi-translate
                      </v-icon>
                    </template>
                    <span>{{ $t('menu.translate') }}</span>
                  </v-tooltip>
                </td>
              </tr>

              <!--NIVEL 1-->
              <template v-if="iten.open">
                <tr
                  v-for="(child, indM) in iten.childs"
                  :key="Math.random() * (indM + 1)"
                >
                  <td>
                    <template v-if="child.childs.length > 0">
                      <v-icon
                        v-if="child.open"
                        style="cursor: pointer;"
                        color="primary"
                        @click="child.open = !child.open"
                      >
                        mdi-minus-box-outline
                      </v-icon>
                      <v-icon
                        v-else
                        style="cursor: pointer;"
                        color="primary"
                        @click="child.open = !child.open"
                      >
                        mdi-plus-box-outline
                      </v-icon>
                    </template>
                  </td>
                  <td>
                    <span
                      v-for="(nameCh, indMChe) in child.name"
                      :key="indMChe"
                      class="ml-4"
                    >
                      {{ $t(nameCh) }}
                      <span v-if="indMChe !== child.name.length - 1"> / </span>
                    </span>
                  </td>
                  <td
                    style="text-align: right;"
                  >
                    <v-tooltip
                      v-if="child.page"
                      color="primary"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          :disabled="!child.page"
                          @click="goUrl(child.page)"
                          v-on="on"
                        >
                          mdi-web
                        </v-icon>
                      </template>
                      <span>
                        {{ $t('btn.views') }}
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          class="ml-4"
                          :disabled="!child.slug"
                          v-on="on"
                          @click="translateView(child.slug)"
                        >
                          mdi-translate
                        </v-icon>
                      </template>
                      <span>{{ $t('menu.translate') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  data() {
    return {
      isDialogExport: false,
      languajeBase: null,
      languajeOther: null,
      languajes: [],
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      cant_sin_trans: 0,
    }
  },
  created() {
    this.profile()
    this.fetchDataFromEndpoints()
  },
  methods: {
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }
      this.isLoading = false
    },
    namePage(names) {
      let name = ''
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < names.length; index++) {
        name += this.$t(names[index])
        if (index !== names.length - 1) {
          name += ' / '
        }
      }

      return name
    },
    async fetchDataFromEndpoints() {
      const jsonAll = {
        page: 1,
        per_page: 1000000000000000,
      }
      const jsonLoad = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,

        search: this.model,
      }
      try {
        const [
          resAll,
          resLoad,
        ] = await Promise.all([
          this.axios
            .post('treeviews/list', jsonAll, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('treeviews/list', jsonLoad, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resAll.data.success === false) { } else {
          this.totalItems = resAll.data.meta.total
        }
        // eslint-disable-next-line no-empty
        if (resLoad.data.success === false) { } else {
          this.items = resLoad.data.data
          this.pagination.current = resLoad.data.meta.current_page
          this.pagination.total = resLoad.data.meta.last_page

          this.totalF = resLoad.data.meta.total
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    async load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.search,
      }
      await this.axios
        .post('treeviews/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    async loadAll() {
      const json = {
        per_page: 100000,
        permisos: this.permisos.split(','),
      }
      await this.axios
        .post('treeviews/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.load()
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },

    changeOpen(pos) {
      this.items[pos].open = !this.items[pos].open
    },
    translateView(slug) {
      localStorage.setItem('slug-translate', slug)
      this.$router.push({ name: 'pages-web' })
    },
    goUrl(page) {
      if (page === 'landing-page') {
        window.open('/', '_blank')
      } else if (page === 'register') {
        window.open('/register', '_blank')
      } else if (page === 'session') {
        window.open('/login', '_blank')
      } else {
        window.open(`/page-static/${page}`, '_blank')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
